import React from 'react';
import Layout from '../components/layout';
import { graphql, Link } from 'gatsby';
import categoryStyles from './category.module.scss';
import Title from '../components/title';
import currensize from '../../util/currencize';

export const query = graphql`
  query($category: String) {
    allMarkdownRemark(
      limit: 2000
      sort: { fields: [frontmatter___group, frontmatter___basePrice], order: ASC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            group
            date
            baseCode
            basePrice
            thumb
            images {
              src
              alt
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        categories {
          name
          slug
          metaDesc
        }
      }
    }
  }
`

// Looks through edges to find is product frontmatter contains a 'group' field
function isGrouped(edges) {
  for (var i = 0; i < edges.length; i++) {
    if (edges[i].node.frontmatter.group) {
      return true;
    }
  }
  return false;
}


// Groups edges into subarrays of their respective groups
// should only run if isGrouped() returns true
// returns { groupName: [edge], groupName: [edge]}
function groupEdges(edges) {
  let returnObj = { ungrouped: [] };
  for (var i = 0; i < edges.length; i++) {
    let groupName = edges[i].node.frontmatter.group;
    // console.log(groupName)
    if (groupName) {
      // does returnObj[groupName] exist? if not, set empty array
      if (!returnObj[groupName]) {
        returnObj[groupName] = [];
      }
      returnObj[groupName].push(edges[i])
    } else {
      returnObj['ungrouped'].push(edges[i])
    }
  }
  return returnObj;
}


function assembleTiles(edges) {
  return (
    <div className={categoryStyles.categoryContainer}>

      <div className="columns is-multiline is-mobile is-centered">
        {edges.map((edge, i) => (
          <div key={`tile-${i}`} className="column is-half-mobile is-one-third-tablet is-one-quarter-desktop">
            <Link to={edge.node.fields.slug}>
              <div className={`catalog-card ${categoryStyles.catalogCard}`}>
                <div className="card-image">
                  <figure className={`image is-1by1 ${categoryStyles.hoverZoomContainer}`}>
                    <img className={categoryStyles.hoverZoom} alt={edge.node.frontmatter.productName} src={edge.node.frontmatter.thumb ? `/images/${edge.node.frontmatter.thumb}` : `/images/${edge.node.frontmatter.images[0].src}`} />
                  </figure>
                </div>
                <div className="content">
                  <p className={categoryStyles.productName}>{edge.node.frontmatter.title}</p>
                  <p className={categoryStyles.productPrice}>{currensize(edge.node.frontmatter.basePrice)}</p>
                </div>

              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}
const Category = (props) => {
  const {edges} = props.data.allMarkdownRemark;
        let groupedEdges;
        if (isGrouped(edges)) {
          groupedEdges = groupEdges(edges);
        }
        // isolating gatsby-config's data for this category
        // we're specifically interested in the category's description for site metadata
        const categoryData = props.data.site.siteMetadata.categories.filter( cat => { return (cat.name === props.pageContext.category) } );
        // console.log(categoryData)
        return (
    <Layout metaDesc={categoryData.length > 0 && categoryData[0].metaDesc} title={`Shop ${props.pageContext.category} at US Safety Equipment.com`}>
          <Title>{props.pageContext.category.toUpperCase()}</Title>
          {isGrouped(edges) ? // grouped. iterate through groups, spit out name them spit out rows
            Object.keys(groupedEdges).map((groupName, i) => {
              if (groupedEdges[groupName] && groupedEdges[groupName].length > 0) { // ungrouped may exist as an empty array
                return (
                  <div key={`group-${i}`}>
                    <h2 className={categoryStyles.groupTitle}>{groupName === 'ungrouped' ? 'Others' : groupName}</h2>
                    <div>
                      {assembleTiles(groupedEdges[groupName])}
                    </div>
                  </div>
                )
              } else {
                return null;
              }
            })
            : // Not grouped, spit them all out
            assembleTiles(edges)
          }


        </Layout>
        )
      }
      
export default Category;